import { CurlyLanguage } from "./parser";

const mapVariables = (variables) => variables.map((variable) => ({ label: variable, type: "variable" }));

const complete = (variables) => (context) => {
  const token = context.matchBefore(/{\w*$/);
  if (!token) {
    return null;
  }
  return {
    from: token.from + 1,
    options: mapVariables(variables),
    validFor: /{\w*/,
  };
};

export const completion = (variables) =>
  CurlyLanguage.data.of({
    autocomplete: complete(variables),
  });
