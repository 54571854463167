import "./App.less";
import { useState } from "react";
import { Layout } from "antd";
import { Redirect, Route, Switch } from "react-router-dom";
import { FeedForm, FeedManualExport } from "./components/Feeds";
import { PartnersList } from "./components/Partners";
import { ReleaseDeliveries, ReleasesList } from "./components/Releases";
import { TemplatesList } from "./components/Templates";
import { Header } from "./components/Layout/Header/Header";
import Login from "./components/Login";
import ResetPassword from "./components/ResetPassword";
import Breadcrumbs from "./components/Layout/Breadcrumbs";
import { PrivateRoute, useAuth, UserRole } from "./utils";
import { useBoolean, useLocalStorageState } from "ahooks";
import { LayoutContext } from "./contexts/layout";
import { FeedPage } from "./components/Feeds/Details/FeedPage";
import { FeedActivity } from "./components/Feeds/Activity/FeedActivity";
import { ActivityPage } from "./components/Activity/ActivityPage";
import { Partner } from "./components/Partners/Details/Partner";
import { PartnerForm } from "./components/Partners/Details/PartnerForm";
import { SettingsPage } from "./components/Settings/SettingsPage";
import { TemplatePage } from "./components/Templates/Details/TemplatePage";
import { TemplateForm } from "./components/Templates/Details/TemplateForm";
import { Sidebar } from "./components/Layout/Sidebar/Sidebar";
import { ReportsList } from "./components/Reports/List/ReportsList";
import { ReportPage } from "./components/Reports/Details/ReportPage";
import { ReportForm } from "./components/Reports/Details/ReportForm";
import { Main } from "./components/Layout/Main/Main";
import cn from "classnames";
import { PartnerActivity } from "@/components/Partners/Activity/PartnerActivity";
import { WebhooksActivityPage } from "@/components/Webhooks/Activity/WebhooksActivityPage";
import { WebhookList } from "@/components/Webhooks/List/WebhookList";
import { WebhookPage } from "./components/Webhooks/Details/WebhookPage";
import { EmailAlertsList } from "@/components/EmailAlerts/List/EmailAlertsList";
import { EmailAlertForm } from "@/components/EmailAlerts/Details/EmailAlertForm";
import { EmailAlertPage } from "@/components/EmailAlerts/Details/EmailAlertPage";
import { WebhookActivityPage } from "@/components/Webhooks/Details/WebhookActivityPage";
import { MessageType } from "@/api/webhooks/types";
import { FlowsList } from "@/components/Flows/List/FlowsList";
import { FlowForm } from "@/components/Flows/Details/FlowForm";
import { FlowPage } from "@/components/Flows/Details/FlowPage";
import { FlowEventList } from "@/components/Flows/FlowEventList/FlowEventList";
import { SalesforceObjectList } from "@/components/SalesforceObjects/List/SalesforceObjectList";
import { ColumnSetPage } from "@/components/Spreadsheets/ColumnSets/Details/ColumnSetPage";
import { ColumnSetForm } from "@/components/Spreadsheets/ColumnSets/Details/ColumnSetForm";
import { ColumnSetList } from "@/components/Spreadsheets/ColumnSets/List/ColumnSetList";
import { OpenplayObjectList } from "@/components/OpenplayObjects/List/OpenplayObjectList";
import { OpenplayObjectPage } from "@/components/OpenplayObjects/Details/OpenplayObjectPage";
import { ExportScheduleList } from "@/components/ExportSchedules/List/ExportScheduleList";
import { ExportScheduleForm } from "@/components/ExportSchedules/Details/ExportScheduleForm";
import { ExportSchedulePage } from "@/components/ExportSchedules/Details/ExportSchedulePage";
import { ScriptList } from "@/components/Scripts/List/ScriptList";
import { ScriptPage } from "@/components/Scripts/Details/ScriptPage";
import { UserPage } from "@/components/Users/Details/UserPage";
import { UserForm } from "@/components/Users/Details/UserForm";
import { UsersList } from "@/components/Users/List";
import { UserProfile } from "@/components/Users/Profile";

const webhookTypeUrlPart = `:type(${Object.values(MessageType).join("|")})`;

function App() {
  const { isAuthenticated } = useAuth();
  const [isSecondary, { setFalse: setPrimary, setTrue: setSecondary }] = useBoolean(false);
  const [isFullWidth, setFullWidth] = useState(false);
  const [isNavigateAllowed, { setFalse: preventNavigation, setTrue: allowNavigation }] = useBoolean(true);

  const [isMenuCollapsed, setIsMenuCollapsed] = useLocalStorageState("sidebar", false);

  const handleMenuCollapse = () => {
    setIsMenuCollapsed((value) => !value);
  };

  return (
    <Layout className="app">
      <LayoutContext.Provider
        value={{
          isSecondary,
          setSecondary,
          setPrimary,
          isNavigateAllowed,
          preventNavigation,
          allowNavigation,
          setFullWidth,
        }}
      >
        {isAuthenticated && <Sidebar collapsed={isMenuCollapsed} />}
        <Layout className={cn("app__content", isSecondary && "app__content--secondary")}>
          {isAuthenticated && (
            <Header
              className="app__header"
              isMenuCollapsed={isMenuCollapsed}
              onCollapse={handleMenuCollapse}
            />
          )}
          <div className="app__scroller">
            <Main fullWidth={isFullWidth}>
              <Breadcrumbs />
              <Switch>
                <PrivateRoute path="/releases/:id" secondaryScreen>
                  <ReleaseDeliveries />
                </PrivateRoute>
                <PrivateRoute path="/releases">
                  <ReleasesList />
                </PrivateRoute>
                <PrivateRoute path="/partners/new/:activeTab" allowedFor={[UserRole.Admin]} secondaryScreen>
                  <PartnerForm isEditingByDefault />
                </PrivateRoute>
                <PrivateRoute
                  path="/partners/:partnerId/feeds/new/:activeTab"
                  allowedFor={[UserRole.Admin]}
                  secondaryScreen
                >
                  <FeedForm isEditingByDefault />
                </PrivateRoute>
                <PrivateRoute path="/partners/:partnerId/feeds/:feedId/manual-export" exact secondaryScreen>
                  <FeedManualExport />
                </PrivateRoute>
                <PrivateRoute path="/partners/:partnerId/feeds/:feedId/activity" exact secondaryScreen>
                  <FeedActivity />
                </PrivateRoute>
                <PrivateRoute path={"/partners/:partnerId/feeds/:feedId/:activeTab"} secondaryScreen>
                  <FeedPage />
                </PrivateRoute>
                <Route
                  path="/partners/:partnerId/feeds/:feedId"
                  exact
                  render={({ location }) => <Redirect to={`${location.pathname}/summary`} />}
                />
                <PrivateRoute
                  path={[
                    "/partners/:partnerId/activity/:targetObject(release)/:operation(import|export)",
                    "/partners/:partnerId/activity/:targetObject(project)/:operation(export)",
                  ]}
                  exact
                  secondaryScreen
                >
                  <PartnerActivity />
                </PrivateRoute>
                <Route
                  path="/partners/:partnerId/activity"
                  render={({
                    match: {
                      params: { partnerId },
                    },
                  }) => <Redirect to={`/partners/${partnerId}/activity/release/export`} />}
                />
                <PrivateRoute path="/partners/:partnerId/:activeTab" secondaryScreen>
                  <Partner />
                </PrivateRoute>
                <Route
                  path="/partners/:partnerId"
                  exact
                  render={({ location }) => <Redirect to={`${location.pathname}/summary`} />}
                />
                <PrivateRoute path="/partners">
                  <PartnersList />
                </PrivateRoute>
                <PrivateRoute path="/users/new" allowedFor={[UserRole.Admin]} secondaryScreen>
                  <UserForm isEditingByDefault />
                </PrivateRoute>
                <PrivateRoute path="/users/:id" allowedFor={[UserRole.Admin]} secondaryScreen>
                  <UserPage />
                </PrivateRoute>
                <PrivateRoute path="/users" allowedFor={[UserRole.Admin]}>
                  <UsersList />
                </PrivateRoute>
                <PrivateRoute path="/templates/new" secondaryScreen>
                  <TemplateForm isEditingByDefault />
                </PrivateRoute>
                <PrivateRoute path="/templates/:id" secondaryScreen>
                  <TemplatePage />
                </PrivateRoute>
                <PrivateRoute path="/templates">
                  <TemplatesList />
                </PrivateRoute>
                <PrivateRoute path="/activity/:targetObject" fullWidth>
                  <ActivityPage />
                </PrivateRoute>
                <PrivateRoute path="/settings" secondaryScreen>
                  <SettingsPage />
                </PrivateRoute>
                <PrivateRoute path="/reports/new/:activeTab" secondaryScreen>
                  <ReportForm isEditingByDefault />
                </PrivateRoute>
                <PrivateRoute path="/reports/:id/:activeTab" secondaryScreen>
                  <ReportPage />
                </PrivateRoute>
                <PrivateRoute path="/reports">
                  <ReportsList />
                </PrivateRoute>
                <PrivateRoute path="/column-sets/new" secondaryScreen>
                  <ColumnSetForm isEditingByDefault />
                </PrivateRoute>
                <PrivateRoute path="/column-sets/:id" secondaryScreen>
                  <ColumnSetPage />
                </PrivateRoute>
                <PrivateRoute path="/column-sets">
                  <ColumnSetList />
                </PrivateRoute>
                <PrivateRoute path="/export-schedules/new" secondaryScreen>
                  <ExportScheduleForm isEditingByDefault />
                </PrivateRoute>
                <PrivateRoute path="/export-schedules/:id" secondaryScreen>
                  <ExportSchedulePage />
                </PrivateRoute>
                <PrivateRoute path="/export-schedules">
                  <ExportScheduleList />
                </PrivateRoute>
                <PrivateRoute path="/webhook-activity">
                  <WebhooksActivityPage />
                </PrivateRoute>
                <PrivateRoute path={`/webhooks/${webhookTypeUrlPart}/activity`} secondaryScreen>
                  <WebhookActivityPage />
                </PrivateRoute>
                <PrivateRoute path={`/webhooks/${webhookTypeUrlPart}`} secondaryScreen>
                  <WebhookPage />
                </PrivateRoute>
                <PrivateRoute path="/webhooks">
                  <WebhookList />
                </PrivateRoute>
                <PrivateRoute path="/email-alerts/new/:activeTab" secondaryScreen>
                  <EmailAlertForm isEditingByDefault />
                </PrivateRoute>
                <PrivateRoute path="/email-alerts/:id/:activeTab" secondaryScreen>
                  <EmailAlertPage />
                </PrivateRoute>
                <Route
                  path="/email-alerts/:id"
                  exact
                  render={({ location }) => <Redirect to={`${location.pathname}/summary`} />}
                />
                <PrivateRoute path="/email-alerts">
                  <EmailAlertsList />
                </PrivateRoute>
                <PrivateRoute path="/flow-events">
                  <FlowEventList />
                </PrivateRoute>
                <PrivateRoute path="/flows/new" secondaryScreen>
                  <FlowForm isEditingByDefault />
                </PrivateRoute>
                <PrivateRoute path="/flows/:id" secondaryScreen>
                  <FlowPage />
                </PrivateRoute>
                <PrivateRoute path="/flows">
                  <FlowsList />
                </PrivateRoute>
                <PrivateRoute path="/scripts/:key" secondaryScreen>
                  <ScriptPage />
                </PrivateRoute>
                <PrivateRoute path="/scripts">
                  <ScriptList />
                </PrivateRoute>
                <PrivateRoute path="/openplay-objects/:name" secondaryScreen>
                  <OpenplayObjectPage />
                </PrivateRoute>
                <PrivateRoute path="/openplay-objects">
                  <OpenplayObjectList />
                </PrivateRoute>
                <PrivateRoute path="/salesforce-objects">
                  <SalesforceObjectList />
                </PrivateRoute>
                <PrivateRoute path="/profile" secondaryScreen>
                  <UserProfile />
                </PrivateRoute>
                <Route path="/login">
                  <Login />
                </Route>
                <Route path="/reset-password">
                  <ResetPassword />
                </Route>
                <Route path="/">
                  <Redirect to="/releases" />
                </Route>
              </Switch>
            </Main>
          </div>
        </Layout>
      </LayoutContext.Provider>
    </Layout>
  );
}

export default App;
