import CodeMirror from "@uiw/react-codemirror";
import { EditorState } from "@codemirror/state";
import { EditorView } from "@codemirror/view";
import { curly } from "./language";
import { theme } from "./theme";
import { Form } from "antd";
import "./TextFieldWithVariables.less";
import cn from "classnames";

type Props = {
  value?: string;
  onChange?: (value: string) => void;
  readOnly?: boolean;
  variables: string[];
  placeholder?: string;
};

export const TextFieldWithVariables = ({
  value,
  onChange,
  readOnly = false,
  variables,
  placeholder,
}: Props) => {
  const { status } = Form.Item.useStatus();
  return (
    <CodeMirror
      placeholder={placeholder}
      className={cn(
        "text-field-with-variables",
        status === "error" && "text-field-with-variables__with-error"
      )}
      height="auto"
      value={value ?? ""}
      onChange={onChange}
      basicSetup={{
        highlightActiveLineGutter: false,
        bracketMatching: true,
        searchKeymap: false,
        lintKeymap: false,
        lineNumbers: false,
        highlightActiveLine: false,
        foldGutter: false,
      }}
      extensions={[
        EditorState.transactionFilter.of((tr) => {
          return tr.newDoc.lines > 1 ? [] : [tr];
        }),
        EditorView.lineWrapping,
        curly(variables),
      ]}
      readOnly={readOnly}
      theme={theme}
    />
  );
};
