import createTheme from "@uiw/codemirror-themes";
import { tags as t } from "@lezer/highlight";

export const theme = createTheme({
  dark: "light",
  settings: {
    background: "#f0f5fa",
    foreground: "#000000",
    fontFamily: "Fira Code, monospace",
    selection: "#2eb8e6",
    gutterBorder: "transparent",
    gutterBackground: "#e1ecf9",
  },
  styles: [{ tag: t.variableName, color: "#8b70f6", fontWeight: 700 }],
});
