// This file was generated by lezer-generator. You probably shouldn't edit it.
import {LRParser} from "@lezer/lr"
export const __parser = LRParser.deserialize({
  version: 14,
  states: "[OQOPOOQOOOOO",
  stateData: "Y~OQPOSPO~O",
  goto: "QPP",
  nodeNames: "⚠ Content Variable",
  maxTerm: 4,
  skippedNodes: [0],
  repeatNodeCount: 0,
  tokenData: "!n~RUO#oe#o#p!P#p#qe#r;'Se;'S;=`y<%lOe~jTS~O#oe#p#qe#r;'Se;'S;=`y<%lOe~|P;=`<%le~!SVtu!P!O!P!P!Q![!P!c!}!P#R#S!P#T#o!P#q#r!i~!nOQ~",
  tokenizers: [0],
  topRules: {"Content":[0,1]},
  tokenPrec: 0
})

export default __parser
// This file was generated by lezer-generator. You probably shouldn't edit it.
export const
  Content = 1,
  Variable = 2
