import parser from "./syntax.grammar";
import { styleTags, tags as t } from "@lezer/highlight";
import { LRLanguage } from "@codemirror/language";

const parserWithMetadata = parser.configure({
  props: [
    styleTags({
      Variable: t.variableName,
      "{ }": t.paren,
    }),
  ],
});

export const CurlyLanguage = LRLanguage.define({
  parser: parserWithMetadata,
});
