import { linter } from "@codemirror/lint";
import { syntaxTree } from "@codemirror/language";

export const getLinter = (variables) =>
  linter((view) => {
    let diagnostics = [];
    syntaxTree(view.state)
      .cursor()
      .iterate((node) => {
        if (node.name === "Variable") {
          const variable = view.state.sliceDoc(node.from + 1, node.to - 1);
          let message = null;
          if (variable.length === 0) {
            message = "The variable name is empty";
          } else if (!variables.includes(variable)) {
            message = "The variable does not exist";
          }
          if (message) {
            diagnostics.push({
              from: node.from,
              to: node.to,
              severity: "error",
              message,
            });
          }
        }
      });
    return diagnostics;
  });
