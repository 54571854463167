import { Form } from "antd";
import React from "react";
import { CONSTANT_MAPPING_PREFIX, EMPTY_VALUE_PLACEHOLDER } from "../../../common/CommonMappings/utils";
import { TextFieldWithVariables, validateVariables } from "../../../common/TextFieldWithVariables";

export const DynamicPathFormItem = ({ name, label }) => {
  const mappings = Form.useWatch(["mapping", "data"]);
  const variablesFromMappings =
    mappings
      ?.map((mapping) => mapping?.source?.[0])
      .filter(Boolean)
      .filter((value) => !(value === EMPTY_VALUE_PLACEHOLDER || value.startsWith(CONSTANT_MAPPING_PREFIX))) ??
    [];
  const pathVariables = ["timestamp", ...variablesFromMappings];

  return (
    <Form.Item
      name={name}
      label={label}
      rules={[validateVariables(pathVariables)]}
      extra="To insert a variable enclose it in curly braces ({})"
    >
      <TextFieldWithVariables placeholder="Path" variables={pathVariables} />
    </Form.Item>
  );
};
